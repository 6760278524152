import { Box } from '@chakra-ui/react'
import React from 'react'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { HubspotModal } from '~components/widgets/hubspot'

const Links: React.FC = () => {
    const { t, lang } = useTranslation('sielbleu')
    const router = useRouter()
    console.log(lang)

    const footerLinks = [
        {
            href: t('sielbleu.landing.footer.sielbleu.link'),
            render: t('sielbleu.landing.footer.sielbleu.name'),
            target: '_blank',
            rel: 'noreferrer',
        },
        {
            href: `/pdfs/${lang}/legal.pdf`,
            render: t('sielbleu.landing.footer.legal'),
            target: '_blank',
            rel: 'noreferrer',
        },
        {
            href: `/pdfs/${lang}/privacy.pdf`,
            render: t('sielbleu.landing.footer.privacy'),
            target: '_blank',
            rel: 'noreferrer',
        },
    ]

    return (
        <Box className="links">
            {footerLinks.map((l) => {
                if (l.target) {
                    return (
                        <a key={l.render} target={l.target} rel={l.rel} href={l.href}>
                            {l.render}
                        </a>
                    )
                }
                return (
                    <Link
                        key={l.render}
                        href={{
                            pathname: l.href,
                            query: router.query,
                        }}
                    >
                        {l.render}
                    </Link>
                )
            })}
            <HubspotModal
                buttonText={t('sielbleu.landing.footer.contact')}
                formLanguage={lang}
                buttonVariant="link"
                buttonStyle={{
                    color: 'inherit',
                    fontWeight: '100',
                    fontSize: '17px',
                    marginTop: '5px',
                    marginBottom: '25px',
                }}
            />
        </Box>
    )
}

export default Links
