import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, useDisclosure, Button } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import * as process from 'process'

type ModalButtonProps = {
    buttonText: string
    formLanguage: string
    buttonVariant?: string
    buttonStyle?: object
}

// As env variables are not available in the browser (they are replaced in build time),
// process.env can't be accessed dynamically and we need to use an object to access them
type hubspotLocales = {
    en: string | undefined
    fr: string | undefined
    es: string | undefined
    it: string | undefined
}
const hubspotFormId: hubspotLocales = {
    en: process.env.NEXT_PUBLIC_HUBSPOT_FORM_CONTACT_ID_EN,
    fr: process.env.NEXT_PUBLIC_HUBSPOT_FORM_CONTACT_ID_FR,
    es: process.env.NEXT_PUBLIC_HUBSPOT_FORM_CONTACT_ID_ES,
    it: process.env.NEXT_PUBLIC_HUBSPOT_FORM_CONTACT_ID_IT,
}

const hubspotFormUrl = {
    en: process.env.NEXT_PUBLIC_HUBSPOT_FORM_CONTACT_URL_EN,
    fr: process.env.NEXT_PUBLIC_HUBSPOT_FORM_CONTACT_URL_FR,
    es: process.env.NEXT_PUBLIC_HUBSPOT_FORM_CONTACT_URL_ES,
    it: process.env.NEXT_PUBLIC_HUBSPOT_FORM_CONTACT_URL_IT,
}
export const HubspotModal: React.FC<ModalButtonProps> = ({ buttonText, formLanguage, buttonVariant, buttonStyle }) => {
    const { t } = useTranslation('sielbleu')
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [hubspotForm, setHubspotForm] = useState<string | null>(null)
    const openHubspotForm = (formId: string | undefined, fallback: string | undefined) => {
        if (!formId) {
            return
        }

        if (window.hbspt && window.hbspt.forms) {
            setHubspotForm(formId)
            onOpen()
            return
        }

        if (fallback) {
            // We noticed the native tracking protection of Firefox blocks the
            // Hubspot script. This is also a general fallback in case of any
            // loading issues of the hubspot script.
            window.location.href = fallback
        }
    }

    useEffect(() => {
        if (isOpen && hubspotForm) {
            window.hbspt.forms.create({
                region: 'eu1',
                portalId: process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_ID,
                formId: hubspotForm,
                target: '#hubspotForm',
            })
        }
    }, [isOpen, hubspotForm])

    return (
        <>
            <Button
                onClick={() =>
                    openHubspotForm(
                        hubspotFormId[formLanguage as keyof hubspotLocales],
                        hubspotFormUrl[formLanguage as keyof hubspotLocales]
                    )
                }
                variant={buttonVariant}
                style={buttonStyle}
            >
                {buttonText}
            </Button>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent paddingY={5} maxWidth={'48rem'}>
                    <ModalCloseButton />
                    <ModalBody id={'hubspotForm'}></ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}
