import { Icon } from '@chakra-ui/react'

const ChevronDownIcon = (props) => (
    <Icon viewBox="0 0 35 34" {...props}>
        <g transform="translate(-1719 -28)">
            <g transform="translate(1728.594 51.375) rotate(-90)">
                <path d="M9.461,15.829a.992.992,0,0,1-1.4,1.4L.291,9.461a.988.988,0,0,1,0-1.4L8.057.291a.991.991,0,0,1,1.4,1.4L2.393,8.759Z" />
            </g>
        </g>
    </Icon>
)

export default ChevronDownIcon
