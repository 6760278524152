import Script from 'next/script'
import useTranslation from 'next-translate/useTranslation'
import { FunctionComponent, useEffect } from 'react'

const Component: FunctionComponent = () => {
    const { lang } = useTranslation()

    const cookiesVersion =
        {
            fr: 'sielbleu_fr',
            en: 'sielbleu_en',
            it: 'sielbleu_it',
            es: 'sielbleu_es',
        }[lang] || 'sielbleu_en'

    return process.env.NEXT_PUBLIC_SIELBLEU_AXEPTIO_CLIENT_ID ? (
        <Script id="axeptio">
            {`
                window.axeptioSettings = {
                  clientId: '${process.env.NEXT_PUBLIC_SIELBLEU_AXEPTIO_CLIENT_ID}',
                  cookiesVersion: '${cookiesVersion}',
                  userCookiesDomain: '${process.env.NEXT_PUBLIC_SIELBLEU_DOMAIN}'
                };

                window.cookieGroups = { performance: [], personalisation: [], advertising: [] };

                (function(d, s) {
                  var t = d.getElementsByTagName(s)[0], e = d.createElement(s);
                  e.async = true; e.src = "//static.axept.io/sdk.js";
                  t.parentNode.insertBefore(e, t);
                })(document, "script");

                (_axcb = window._axcb || []).push(function(sdk) {
                  sdk.on("cookies:complete", function(choices) {
                    for (var group in window.cookieGroups) {
                      if (choices[group]) {
                        // Init selected cookie groups
                        for (var i = 0; i <= window.cookieGroups[group].length; i++) {
                          if (typeof window.cookieGroups[group][i] == 'function') {
                            window.cookieGroups[group][i]();
                          }
                        }
                      }
                    }
                  });
                });
            `}
        </Script>
    ) : null
}

export default Component
