import React, { useEffect, useState } from 'react'
import { useStyleConfig, Box, Divider, Link as ChakraLink } from '@chakra-ui/react'
import Header from '~components/sielbleu/layout/header'
import { useNotifications } from '~components/sielbleu/contexts/NotificationContext'
import { routes } from '~components/sielbleu/utils/routes'
import { useRouter } from 'next/router'
import Banner from '~ui/components/Banner'
import useTranslation from 'next-translate/useTranslation'
import { NotificationProvider } from '~components/sielbleu/contexts/NotificationContext'
import Locale from '~components/sielbleu/layout/header/locale'
import Nav from '~components/sielbleu/layout/header/nav'
import { CONNECTED, LANDING, LANDING_SUBDOMAIN, REGISTER } from '~components/sielbleu/utils/const'
import Footer from '~components/sielbleu/layout/footer'
import Links from '~components/sielbleu/layout/footer/links'
import Social from '~components/sielbleu/layout/footer/social'
import Logo from '~components/sielbleu/layout/footer/logo'
import AxeptioScript from '~components/sielbleu/scripts/axeptio'
import { openToast } from '~components/toast'
import { mutate } from '~lib/graphql'
import HeadLinks from '~components/sielbleu//head/links'
import { SielbleuSendVerifyEmailLinkDocument } from '~graphql/types'
import { getSubdomain } from '~components/sielbleu/utils/redirections'
import Head from 'next/head'
import { useAuth } from '~lib/auth'
import Script from 'next/script'

const Layout: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const { t } = useTranslation('sielbleu')
    const { notifications, resetNotifications } = useNotifications()
    const router = useRouter()
    const [variant, setVariant] = useState<string | undefined>(undefined)
    const [headerVariant, setHeaderVariant] = useState<string | undefined>(undefined)
    const [footerVariant, setFooterVariant] = useState<string | undefined>(undefined)
    const [pathName, setPathName] = useState<string | null>(null)
    const subdomain = getSubdomain()
    const { me } = useAuth()

    useEffect(() => {
        if (router.isReady) {
            setPathName(router?.asPath?.split('?')[0])
        }
    }, [router])

    useEffect(() => {
        if (pathName) {
            if (Object.values(routes.public).includes(pathName)) {
                setVariant(REGISTER)
            }
            if (Object.values(routes.connected).includes(pathName) || pathName.startsWith('/calendar/')) {
                setVariant(CONNECTED)
            }
            // if user is logged keep header connected style even on public page (expect for login page)
            me ? setHeaderVariant(CONNECTED) : setHeaderVariant(REGISTER)

            if (LANDING_SUBDOMAIN.includes(subdomain || '')) {
                setVariant(LANDING)
                setHeaderVariant(LANDING)
                setFooterVariant(LANDING)
            }
        }
    }, [me, pathName])

    const headerStyles = useStyleConfig('Header', { variant: headerVariant })
    const footerStyles = useStyleConfig('Footer', { variant: footerVariant })
    const pageStyles = useStyleConfig('Page', { variant })
    const emailToConfirm = me && !me?.hasVerifiedEmail
    const [isSubmitting, setSubmitting] = useState<boolean>(false)

    const bannerCta = () => {
        const SEND_LINK_ERROR = 'send_link_error'
        const SEND_LINK_CONFIRMATION = 'send_link_confirmation'

        const sendEmail = () => {
            if (isSubmitting) {
                return
            }

            setSubmitting(true)
            mutate(SielbleuSendVerifyEmailLinkDocument)
                .then(() => {
                    openToast({ id: SEND_LINK_CONFIRMATION, title: t`sielbleu.banner.email_sent`, status: 'success' })
                })
                .catch((e) => {
                    setSubmitting(false)
                    openToast({ id: SEND_LINK_ERROR, title: t`sielbleu.error.generic` })
                })
            return false
        }

        return (
            <ChakraLink href="#" mx="1" onClick={sendEmail}>
                {t`sielbleu.banner.resend_email`}
            </ChakraLink>
        )
    }

    useEffect(() => {
        if (notifications && notifications.length) {
            notifications.forEach((notification, index) =>
                openToast({
                    id: `notification_${index}`,
                    title: notification.title,
                    status: notification.status,
                })
            )
            resetNotifications()
        }
    }, [notifications])

    return (
        <>
            <HeadLinks />
            <AxeptioScript />
            <div hidden={!variant}>
                <Box __css={headerStyles}>
                    <Header>
                        {headerVariant === CONNECTED || headerVariant === LANDING ? (
                            <>
                                <Box className="nav">
                                    {headerVariant === LANDING && <Locale />}
                                    {<Nav variant={headerVariant} />}
                                </Box>
                            </>
                        ) : (
                            <Box className="header-register-cta">
                                {<Locale />}
                                {/*<SignLink />*/}
                            </Box>
                        )}
                    </Header>
                </Box>
                {emailToConfirm && pathName && Object.values(routes.connected).includes(pathName) && (
                    <Banner variant="info" content={t(`sielbleu.banner.email`)} cta={bannerCta()} />
                )}
                <Box __css={pageStyles}>{children}</Box>
                {footerVariant === LANDING && (
                    <Box __css={footerStyles}>
                        <Footer>
                            <Box className="footer-container">
                                <Box className="follow-us">
                                    <Logo />
                                    <Social />
                                </Box>
                                <Divider />
                                <Links />
                            </Box>
                        </Footer>
                    </Box>
                )}
            </div>
        </>
    )
}

const SBLayout: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
    <NotificationProvider>
        <>
            <Head>
                <title>Siel Bleu</title>
            </Head>
            <Layout>{children}</Layout>
            <Script src="//js-eu1.hsforms.net/forms/embed/v2.js" />
        </>
    </NotificationProvider>
)

export default SBLayout
