import { Box, Text } from '@chakra-ui/react'
import React from 'react'
import FacebookIcon from '~ui/icons/FacebookIcon'
import XIcon from '~ui/icons/XIcon'
import YoutubeIcon from '~ui/icons/YoutubeIcon'
import useTranslation from 'next-translate/useTranslation'
import LinkedinIcon from '~ui/icons/LinkedinIcon'
import InstagramIcon from '~ui/icons/InstagramIcon'

const Social: React.FC = () => {
    const { t } = useTranslation('sielbleu')
    return (
        <Box className="social">
            <Text textTransform="uppercase">{`${t(`sielbleu.landing.footer.follow`)} :`}</Text>
            <Box className="icons">
                <a className="facebook" target="_blank" rel="noreferrer" href={t(`sielbleu.landing.footer.fb.link`)}>
                    <FacebookIcon boxSize={6} />
                </a>
                <a className="twitter" target="_blank" rel="noreferrer" href={t(`sielbleu.landing.footer.tw.link`)}>
                    <XIcon boxSize={6} />
                </a>
                <a className="linkedin" target="_blank" rel="noreferrer" href={t(`sielbleu.landing.footer.li.link`)}>
                    <LinkedinIcon boxSize={6} />
                </a>
                <a className="youtube" target="_blank" rel="noreferrer" href={t(`sielbleu.landing.footer.yt.link`)}>
                    <YoutubeIcon boxSize={6} />
                </a>
                <a className="instagram" target="_blank" rel="noreferrer" href={t(`sielbleu.landing.footer.ig.link`)}>
                    <InstagramIcon boxSize={6} />
                </a>
            </Box>
        </Box>
    )
}

export default Social
