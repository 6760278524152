import { Box, Divider, Flex, Img, Link } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { BPCO, COURS_CO, FPI, RESPIFIL } from '~components/sielbleu/utils/const'
import useTranslation from 'next-translate/useTranslation'
import { getSubdomain, redirectToDefaultUrl } from '~components/sielbleu/utils/redirections'
import { useAuth } from '~lib/auth'

const Logo: React.FC = () => {
    const { lang } = useTranslation('sielbleu')

    const { me } = useAuth()

    const router = useRouter()
    const { query } = router
    const pathname = router.asPath.split('?')[0]
    const type = query?.type
    const [subdomain, setSubdomain] = useState('')

    // specific config for respifil and generic platform are set this way to avoid hydration mismatch between server and client
    const specificSubdomains = ['respifil-apa', 'generic', 'online']

    const setSubdomains = (subdomains: string[]) => {
        if (subdomains.some((e) => e === getSubdomain())) {
            const toto = getSubdomain() as string
            setSubdomain(toto)
        }
    }
    useEffect(() => {
        setSubdomains(specificSubdomains)
    }, [])

    const handleClick = () => {
        if (me) {
            redirectToDefaultUrl(me, router)
            return
        }

        if (type === BPCO) {
            window.location.href = process.env.NEXT_PUBLIC_LANDING_BPCO_URL + '/' + lang || '/'
            return
        }

        if (type === FPI) {
            window.location.href = process.env.NEXT_PUBLIC_LANDING_FPI_URL + '/' + lang || '/'
        }

        if (type === COURS_CO || router.query?.client_id) {
            window.location.href = process.env.NEXT_PUBLIC_SIELBLEU_COURS_CO_URL || ''
            return
        }

        if (type === RESPIFIL) {
            window.location.href = process.env.NEXT_PUBLIC_LANDING_RESPIFIL_URL + '/' + lang || '/'
        }

        router.push({ pathname, query })
    }

    // while waiting to have confirmation of the logo display change everywhere,
    // the specific ui for generic platform is set this way
    if (subdomain === 'generic' || subdomain === 'online') {
        return (
            <Box zIndex="2" position="relative" width={{ base: '125px', md: '174px' }}>
                <Link
                    position="absolute"
                    top={{ base: '8px' }}
                    left={{ base: '14px' }}
                    href="https://www.sielbleu.org/"
                    target="_blank"
                >
                    <Img
                        width={{ base: '125px', md: '174px' }}
                        objectFit="cover"
                        src={'/images/sielbleu/logo_siel_bleu.png'}
                        alt="siel bleu logo"
                    />
                </Link>
            </Box>
        )
    }

    return (
        <Box className="logo" onClick={() => handleClick()}>
            <Flex align="center">
                <Img
                    width={{ base: '68px', md: '79px' }}
                    objectFit="cover"
                    src={'/images/sielbleu/logo_siel_bleu.png'}
                    alt="siel bleu logo"
                />
                {subdomain === 'respifil-apa' && (
                    <>
                        <Divider orientation="vertical" mx={5} />
                        <Img
                            width={{ base: '170px', md: '170px' }}
                            objectFit="cover"
                            src={'/images/sielbleu/respifil-apa/logo_respifil.svg'}
                            alt="respifil logo"
                        />
                    </>
                )}
                {me?.prescriber?.logo && (
                    <>
                        <Divider
                            orientation="vertical"
                            mx={5}
                            h="76px"
                            borderColor={'grey.medium'}
                            borderWidth="1.3px"
                        />
                        <Img height="79px" objectFit="contain" src={me.prescriber.logo} alt="Logo" />
                    </>
                )}
            </Flex>
        </Box>
    )
}

export default Logo
