import { routes } from './routes'
import { NextRouter } from 'next/dist/shared/lib/router/router'
import { getCookie, getCookies } from 'cookies-next'
import { SielbleuUserFragment } from '~graphql/types'
import { getDashboard } from '~components/sielbleu/config/utils'
import { cookieName } from '~lib/cookies'

export const redirectToDefaultUrl = async (
    me: SielbleuUserFragment | undefined | null,
    router: NextRouter,
    redirectionPath?: string,
    redirectionQuery?: any
) => {
    if (me?.groupClassUser && !me.prescriber?.name) {
        // redirect to sso url so beneficiary is redirected logged to cours co
        const response = await fetch(routes.api.loginSsoUrl)
        const data = await response.json()

        window.location.href = data.url || process.env.NEXT_PUBLIC_SIELBLEU_COURS_CO_URL || ''
        return
    }
    if (me?.prescriber?.name) {
        await router.push(
            {
                pathname:
                    redirectionPath ||
                    // redirect to dashboard according to prescriber config
                    (await getDashboard(me.prescriber)) ||
                    // fallback to settings if really we can't find any features
                    routes.connected.settings,
                query: redirectionQuery || {},
            },
            '',
            { locale: getCookies().NEXT_LOCALE || me?.locale }
        )
        return
    }
}

export const getRedirectionUrlFromQuery = (query: NextRouter['query'] | null) => {
    const decodedNextUrl = query?.redirect && decodeURIComponent(query?.redirect as string)
    // We remove the numeric id in URL if existing
    // Example: /adapted-sessions/workout/1 becomes /adapted-sessions/workout
    const redirect =
        decodedNextUrl && Object.values(routes.connected).includes(decodedNextUrl.replace(/\/[0-9]+$/, ''))
            ? (decodedNextUrl as string)
            : null
    if (redirect) {
        delete query?.redirect
    }
    return { pathname: redirect, query }
}

export const getProtocol = () => {
    if (typeof window === 'undefined') {
        return process.env.NODE_ENV === 'production' ? 'https://' : 'http://'
    }
    return window.location.protocol + '//'
}

export const getSubdomain = () => {
    if (typeof window !== 'undefined') {
        const regexSubdomain = /(?:http[s]*\:\/\/)*(.*?)\.(?=[^\/]*\..{2,5})/i
        const subdomain = window.location.origin.match(regexSubdomain)
        return subdomain ? subdomain[1] : ''
    }
}

export const isValidUrl = (url: string): boolean => {
    try {
        new URL(url)
        return true
    } catch (e) {
        return false
    }
}
