import {
    Box,
    Breadcrumb,
    BreadcrumbItem,
    HStack,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Show,
    Text,
    useDisclosure,
} from '@chakra-ui/react'
import React, { useEffect, useMemo, useState } from 'react'
import ActiveLink from '~ui/components/ActiveLink'
import useTranslation from 'next-translate/useTranslation'
import { routes } from '~components/sielbleu/utils/routes'
import HamburgerMenuIcon from '~ui/icons/HamburgerMenuIcon'
import ProfileIcon from '~ui/icons/ProfileIcon'
import ChevronDownIcon from '~ui/icons/ChevronDownIcon'
import { BPCO, CONNECTED, FPI, GREY_HEAVY, LANDING, RESPIFIL, SIELBLEU, WORK } from '~components/sielbleu/utils/const'
import { SielbleuUserFragment } from '~graphql/types'
import { availableRoutes } from '~components/sielbleu/config/routes'
import { getProtocol, getSubdomain } from '~components/sielbleu/utils/redirections'

import { useRouter } from 'next/router'
import { useAuth } from '~lib/auth'
import { HubspotModal } from '~components/widgets/hubspot'

interface navLink {
    href: string
    params?: string
    render: any
    target?: boolean
    variant?: string
}

const Nav: React.FC<{ variant: string }> = ({ variant }) => {
    const { t, lang } = useTranslation('sielbleu')
    const { isOpen, onOpen, onClose } = useDisclosure()
    const router = useRouter()

    const { me } = useAuth()

    const protocol = getProtocol()
    const [connectedLinks, setConnectedLinks] = useState<Array<navLink>>([])
    const memoConnectedLinks = useMemo(() => connectedLinks, [connectedLinks])

    useEffect(() => {
        if (variant === CONNECTED && me) {
            generateConnectedLinks(me, t).then((links) => {
                setConnectedLinks(links)
            })
        }

        return () => {
            setConnectedLinks([])
        }
    }, [variant, me])

    const handleRegisterType = (): string => {
        const subdomain = getSubdomain()

        switch (subdomain) {
            case 'pf-and-me':
                return FPI
            case 'bpco':
            case 'copd':
                return BPCO
            case 'respifil-apa':
                return RESPIFIL
            case 'work':
                return WORK
            default:
                return SIELBLEU
        }
    }

    const handleLogout = () => {
        if (me && me.groupClassUser && !me.prescriber?.name) {
            window.location.href =
                process.env.NEXT_PUBLIC_SIELBLEU_COURS_CO_URL +
                '/logout-return?redirect_to=' +
                protocol +
                process.env.NEXT_PUBLIC_SIELBLEU_DOMAIN +
                routes.public.logout
            return
        }
        router.push(routes.public.logout)
    }
    const generateConnectedLinks = async (
        me: SielbleuUserFragment | null,
        t: (key: string) => string
    ): Promise<Array<navLink>> => {
        if (!me?.prescriber?.name) {
            return []
        }

        return availableRoutes
            .filter(({ route, feature }) => me.prescriber?.supportedFeatures?.includes(feature))
            .map(({ route }) => ({
                href: route,
                render: t(`sielbleu.${route.substring(1)}.name`),
            }))
            .concat([{ href: routes.connected.statistics, render: t(`sielbleu.statistics.name`) }])
    }

    const landingLinks: Array<navLink> = [
        {
            href: protocol + process.env.NEXT_PUBLIC_SIELBLEU_DOMAIN + '/' + lang + routes.public.login,
            params: `type=${handleRegisterType()}`,
            render: t(`sielbleu.landing.signin`),
            variant: 'button',
        },
    ]

    const NavLinks = ({ links }: { links: Array<navLink> }) => {
        const shouldDisplay = links.length > 1 || !me
        return (
            <>
                {shouldDisplay &&
                    links.map((link: navLink) => (
                        <BreadcrumbItem key={link.href}>
                            <ActiveLink
                                variant={link.variant || 'nav'}
                                href={link.href}
                                params={link.params}
                                target={!!link.target}
                            >
                                {link.render}
                            </ActiveLink>
                        </BreadcrumbItem>
                    ))}
            </>
        )
    }

    const MenuLinks = ({ links }: { links: Array<navLink> }) => {
        return (
            <>
                <Box className="nav-menu-links">
                    {links.map((link: navLink) => (
                        <ActiveLink key={link.href} variant={link.variant || 'nav'} href={link.href}>
                            {link.variant === 'button' ? link.render : <MenuItem>{link.render}</MenuItem>}
                        </ActiveLink>
                    ))}
                </Box>
            </>
        )
    }

    return (
        <>
            <Breadcrumb separator="" className="nav-bar-links">
                <NavLinks links={variant === CONNECTED ? memoConnectedLinks : landingLinks} />
            </Breadcrumb>
            <Menu gutter={0} closeOnBlur closeOnSelect isOpen={isOpen}>
                <MenuButton h="100%" onClick={isOpen ? onClose : onOpen} onMouseEnter={onOpen} onMouseLeave={onClose}>
                    <Box className="nav-menu-button">
                        <HamburgerMenuIcon boxSize={6} />
                        <Text className="text">MENU</Text>
                    </Box>
                    {me && variant != LANDING && (
                        <HStack className="nav-menu-profile">
                            <ProfileIcon boxSize={8} />
                            <Text className="name" textTransform="capitalize">
                                {me.firstName || ''}
                            </Text>
                            <ChevronDownIcon fill={GREY_HEAVY} boxSize={8} />
                        </HStack>
                    )}
                </MenuButton>
                <MenuList onMouseEnter={onOpen} onMouseLeave={onClose}>
                    <MenuLinks links={variant === CONNECTED ? memoConnectedLinks : landingLinks} />
                    {me && variant != LANDING && (
                        <>
                            <Show below="md">
                                <MenuDivider className="menu-divider" />
                            </Show>
                            <ActiveLink variant="nav" href={routes.connected.settings}>
                                <MenuItem>{t(`sielbleu.settings.name`)}</MenuItem>
                            </ActiveLink>
                            <MenuItem>
                                <HubspotModal
                                    formLanguage={lang}
                                    buttonText={t(`sielbleu.landing.footer.contact`)}
                                    buttonVariant="link"
                                    buttonStyle={{
                                        color: 'inherit',
                                        fontWeight: 'normal',
                                        _hover: {
                                            cursor: 'pointer',
                                            textDecoration: 'underline',
                                        },
                                    }}
                                />
                            </MenuItem>
                            <MenuDivider />
                            <MenuItem onClick={handleLogout}>
                                <Text variant="link">{t(`sielbleu.register.cta.signout`)}</Text>
                            </MenuItem>
                        </>
                    )}
                </MenuList>
            </Menu>
        </>
    )
}

export default Nav
