import { SielbleuPrescriber, SielbleuPrescriberFeature, SielbleuUserFragment } from '~graphql/types'
import { COURS_CO, SIELBLEU } from '~components/sielbleu/utils/const'
import { routes } from '~components/sielbleu/utils/routes'
import { availableRoutes } from '~components/sielbleu/config/routes'

// @see documentation for web configurations
// https://fizzup.atlassian.net/wiki/spaces/FDO/pages/2168094721/Siel+Bleu+web+configurations

export const getDashboard = async (prescriber: SielbleuPrescriber | null): Promise<any> => {
    if (!prescriber) {
        return null
    }

    const supportedRoutes = availableRoutes.filter(({ route, feature }) =>
        prescriber?.supportedFeatures?.includes(feature)
    )

    return supportedRoutes.length ? supportedRoutes[0].route : null
}

export const getRegisterConfig = async (config: string): Promise<any> => {
    try {
        return await import(`~components/sielbleu/config/${config.toLowerCase()}/register.ts`)
    } catch (error) {
        throw error
    }
}
