import { Box, Img, Link } from '@chakra-ui/react'
import React from 'react'

const Logo: React.FC = () => {
    return (
        <Box className="logo-footer">
            <Link href="https://www.sielbleu.org/" target="_blank">
                <Img objectFit="cover" src="/images/sielbleu/logo_siel_bleu.png" alt="siel bleu logo" />
            </Link>
        </Box>
    )
}

export default Logo
