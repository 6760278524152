import { Box, Select } from '@chakra-ui/react'
import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import setLanguage from 'next-translate/setLanguage'
import { useRouter } from 'next/router'
import { setCookie } from 'cookies-next'

const availableLocales = (pathname: string): string[] => {
    if (pathname.includes('bpco')) {
        return ['fr', 'es']
    }
    if (
        pathname.includes('respifil-apa') ||
        pathname.includes('work') ||
        pathname.includes('online') ||
        pathname.includes('generic')
    ) {
        return ['fr']
    }
    return ['fr', 'en', 'es', 'it']
}

const Locales: React.FC = () => {
    const { lang } = useTranslation()
    const router = useRouter()

    const handleLocale = async (locale: string) => {
        if (locale) {
            setCookie('NEXT_LOCALE', locale)
            await setLanguage(locale)
        }
    }

    if (availableLocales(router.pathname).length <= 1) {
        return null
    }

    return (
        <Box className="locale">
            <Select variant="borderless" defaultValue={lang} onChange={(e) => handleLocale(e.target.value)}>
                {availableLocales(router.pathname).map((locale, index) => {
                    return (
                        <option value={locale} key={index}>
                            {locale.toUpperCase()}
                        </option>
                    )
                })}
            </Select>
        </Box>
    )
}

export default Locales
