import React, { FunctionComponent } from 'react'
import Head from 'next/head'

const Component: FunctionComponent = () => {
    return (
        <Head>
            <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="//cdn.fizzup.com/full/sielbleu/favicon/siel_bleu_logo_32x32.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="96x96"
                href="//cdn.fizzup.com/full/sielbleu/favicon/siel_bleu_logo_96x96.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href="//cdn.fizzup.com/full/sielbleu/favicon/siel_bleu_logo_16x16.png"
            />
        </Head>
    )
}

export default Component
