import {
    Alert,
    Box,
    AlertDescription,
    AlertIcon,
    CloseButton,
    useDisclosure,
    useStyleConfig,
    Img,
} from '@chakra-ui/react'
import React, { useState } from 'react'

const Banner = ({
    variant = 'info',
    content = '',
    cta = null,
}: {
    variant: 'info' | 'warning' | 'success' | 'error' | 'loading'
    content: string
    cta: any
}) => {
    const styles = useStyleConfig('Banner', { variant })
    const { isOpen: isVisible, onClose } = useDisclosure({ defaultIsOpen: true })

    return isVisible ? (
        <Box __css={styles}>
            <Alert className="alert" status={variant}>
                <Box className="container">
                    <AlertIcon />
                    <AlertDescription className="content">
                        {content}
                        {cta}
                    </AlertDescription>
                </Box>
                <CloseButton className="close" onClick={onClose} />
            </Alert>
        </Box>
    ) : (
        <></>
    )
}

export default Banner
