import { getCookies } from 'cookies-next'
import { cookieName } from '~lib/cookies'
import { useMemo } from 'react'
import { GetServerSideProps, GetServerSidePropsContext } from 'next'
import { RefreshTokenError } from '~lib/graphql'
import { ApolloError } from '@apollo/client'
import { routes } from '~components/sielbleu/utils/routes'

export const useAuth = () => {
    const cookieValue = getCookies()[cookieName('me')]

    const me = useMemo(() => {
        try {
            return cookieValue ? JSON.parse(cookieValue) : null
        } catch (e) {
            // Cookie is invalid
            return null
        }
    }, [cookieValue])

    return { me }
}

export const handleAuthenticationErrors = (callback: GetServerSideProps) => {
    return async (context: GetServerSidePropsContext) => {
        try {
            return await callback(context)
        } catch (error) {
            if (error instanceof ApolloError && error.networkError instanceof RefreshTokenError) {
                return {
                    redirect: {
                        destination: routes.public.login,
                        permanent: false,
                    },
                }
            }
            throw error
        }
    }
}
