import { Flex } from '@chakra-ui/react'
import React from 'react'
import Logo from './logo'

const Header: React.FC<{ children?: React.ReactNode }> = ({ children = null }) => {
    return (
        <Flex className="header">
            <Logo />
            {children}
        </Flex>
    )
}

export default Header
