import { Icon } from '@chakra-ui/react'

const ProfileIcon = (props) => (
    <Icon viewBox="0 0 37 37" {...props}>
        <defs>
            <clipPath id="clip-path">
                <rect id="Rectangle_1649" data-name="Rectangle 1649" width="17.813" height="17.451" fill="#6e6e6e" />
            </clipPath>
        </defs>
        <g id="siel_bleu_profile" transform="translate(-1719 -28)">
            <circle id="avis01" cx="18.5" cy="18.5" r="18.5" transform="translate(1719 28)" fill="none" />
            <g id="Groupe_2459" data-name="Groupe 2459" transform="translate(1728.594 37.775)">
                <g id="Groupe_2458" data-name="Groupe 2458" clipPath="url(#clip-path)">
                    <path
                        id="Tracé_4732"
                        data-name="Tracé 4732"
                        d="M102.394,9.636a4.819,4.819,0,1,0-4.819-4.819,4.819,4.819,0,0,0,4.819,4.819"
                        transform="translate(-93.487 0.001)"
                        fill="#6e6e6e"
                    />
                    <path
                        id="Tracé_4733"
                        data-name="Tracé 4733"
                        d="M17.813,234.868h0a8.9,8.9,0,0,0-4.777-7.854.452.452,0,0,0-.484.045,6.017,6.017,0,0,1-7.29,0,.452.452,0,0,0-.484-.045A8.9,8.9,0,0,0,0,234.868H0s0,.005,0,.008,0,.021,0,.031H17.813c0-.01,0-.021,0-.031s0-.005,0-.008"
                        transform="translate(0 -217.455)"
                        fill="#6e6e6e"
                    />
                </g>
            </g>
        </g>
    </Icon>
)

export default ProfileIcon
