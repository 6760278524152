import NextLink from 'next/link'
import { Box, Link as ChakraLink, useStyleConfig } from '@chakra-ui/react'
import React from 'react'
import { useRouter } from 'next/router'

const ActiveLink = ({
    children,
    href = '#',
    params = '',
    variant = 'primary',
    target = false,
}: {
    children: React.ReactNode
    href: string
    params?: string
    variant?: string
    target?: boolean
}) => {
    const styles = useStyleConfig('Link', { variant })
    const router = useRouter()
    const pathName = router?.isReady && router?.asPath.split('?')[0]
    const isActive = variant != 'primary' && pathName === href

    return (
        <Box __css={styles}>
            <NextLink
                href={{
                    pathname: href,
                    query: params ? params : router?.query,
                }}
                passHref
            >
                <ChakraLink target={target ? '_blank' : undefined} className={isActive ? 'active' : ''}>
                    {children}
                </ChakraLink>
            </NextLink>
        </Box>
    )
}

export default ActiveLink
