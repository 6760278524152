import React, { createContext, useContext } from 'react'
import { useSessionStorage } from 'usehooks-ts'

interface Notification {
    status: 'loading' | 'error' | 'info' | 'warning' | 'success' | undefined
    title: string
}

const NotificationContext = createContext<{
    notifications: Notification[]
    pushNotification(notification: Notification): void
    resetNotifications(): void
}>({
    notifications: [],
    pushNotification(notification: Notification): void {},
    resetNotifications(): void {},
})

export const NotificationProvider = (props: any) => {
    const [notifications, setNotifications] = useSessionStorage<Notification[]>('notifications', [])

    const pushNotification = (notification: Notification) => {
        setNotifications([...notifications, notification])
    }

    const resetNotifications = () => {
        setNotifications([])
    }

    return <NotificationContext.Provider value={{ notifications, pushNotification, resetNotifications }} {...props} />
}

export const useNotifications = () => useContext(NotificationContext)
export const NotificationConsumer = NotificationContext.Consumer
