import { Icon } from '@chakra-ui/react'

const XIcon = (props) => (
    <Icon viewBox="0 0 1373.31 1373.31" {...props}>
        <g id="Calque_1-2">
            <path
                d="M740.97,641.98l212.54-247.06h-50.37l-184.55,214.52-147.4-214.52h-170.01l222.9,324.4-222.9,259.09h50.37l194.89-226.54,155.67,226.54h170.01l-231.16-336.42h.01ZM671.98,722.17l-22.58-32.3-179.7-257.04h77.36l145.02,207.44,22.58,32.3,188.5,269.63h-77.36l-153.82-220.02h0Z"
                fill="#fff"
            />
        </g>
    </Icon>
)

export default XIcon
